.content-header .icon {
  border: 2px solid var(--secondary);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 8px;
  transition: transform 0.5s ease;
}

.content-header .icon:hover {
  transform: scale(1.1);
}
