.navbar .fade-in {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar .underline {
  text-decoration: underline;
}
