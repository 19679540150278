:root {
  font-size: 16px;
  font-family: "Vito";
  --primary: #f7f7f7;
  --secondary: #353535;
  --tertiary: #7b7b7b;
  --accent: #b19cd8;
  --borderRadius: 16px;
}

html,
body {
  padding: 0;
  margin: 0;
  caret-color: transparent; /* remove text cursor */
  width: "100vw";
  background-color: var(--primary);
}

/* debug */

/* * {
  outline: 1px solid #f00;
} */

/* fonts */

@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/Avenir/Avenir-Book.otf");
}

@font-face {
  font-family: "AvenirBold";
  src: url("../assets/fonts/Avenir/Avenir-Roman.otf");
}

@font-face {
  font-family: "AvenirBlacker";
  src: url("../assets/fonts/Avenir/Avenir-Black.otf");
}

@font-face {
  font-family: "Vito";
  src: url("../assets/fonts/Vito/Vito-CondensedRegular.woff");
}

@font-face {
  font-family: "Caveat";
  src: url("../assets/fonts/Caveat/Caveat-VariableFont_wght.ttf");
}

/* default text styles */

h1 {
  margin: 0;
  margin-left: -2px;
  font-family: "AvenirBlacker";
  font-size: calc(24px + 2vw);
  color: var(--secondary);
}

h2 {
  margin: 0;
  margin-top: 1.5em;
  font-family: "AvenirBlacker";
  font-size: calc(22px + 1vw);
  color: var(--secondary);
}

h3 {
  margin: 0;
  margin-top: 1em;
  font-family: "AvenirBold";
  font-size: calc(22px + 0.2vw);
  color: var(--secondary);
}

h4 {
  margin: 0;
  margin-top: 0.75em;
  font-family: "Avenir";
  font-size: 22px;
  color: var(--secondary);
}

p {
  margin: 0;
  font-family: "Avenir";
  font-size: 18px;
  margin-top: 16px;
  color: var(--tertiary);
}

b {
  margin: 0;
  font-family: "AvenirBold";
  font-size: 18px;
  margin-top: 16px;
  color: var(--secondary);
}

li {
  font-family: "Avenir";
  font-size: 22px;
  margin-bottom: 6px;
  color: var(--secondary);
}

a {
  text-decoration-color: var(--secondary);
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.tertiary {
  color: var(--tertiary);
}

.accent {
  color: var(--accent);
}

/* common animations */
.fade-in-0 {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  opacity: 0;
}

.fade-in-1 {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.25s;
  opacity: 0;
}

.fade-in-2 {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
  opacity: 0;
}

.fade-in-3 {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.75s;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.75s;
  opacity: 0;
}

.fade-in-4 {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
  opacity: 0;
}

.fade-in-5 {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.25s;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 1.25;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
